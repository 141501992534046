import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { useSupaContext } from "../../contexts/SupaContext";

const PrivateRoute = ({ children }) => {
  const { user } = useSupaContext();
  return user ? children : <Navigate to={"/ingresar"} />;
};

export default PrivateRoute;
