import React, { useState } from "react";
import NumbersSelect from "../ui/NumbersSelect";
import { Button, Badge } from "@radix-ui/themes";
import DividerComponent from "../ui/DividerComponent";
import { Info } from "lucide-react";
import Banner from "./Banner";
import { useNavigate } from "react-router-dom";
import Sidepanel from "../navigation/Sidepanel";
import { useSalesContext } from "../../contexts/SalesContext";

const OrderStarter = () => {
  const navigate = useNavigate();
  const {
    selectedesSugs,
    setSelectedesSugs,
    selectedesRefills,
    setSelectedesRefills,
  } = useSalesContext();
  const images = {
    image01:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-sug-no-back-01.jpg",
    image02:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-sug-no-back-02.jpg",
    image03:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-sug-no-back-03.jpg",
    image04:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-sug-no-back-04.jpg",
    image05:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-sug-no-back-05.jpg",
  };
  const imagesNoBg = {
    image01:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-sug-no-back-01.png",
    image02:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-sug-no-back-02.png",
    image03:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-sug-no-back-03.png",
    image04:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-sug-no-back-04.png",
    image05:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-sug-no-back-05.png ",
  };

  function getImage() {
    switch (selectedesSugs) {
      case 1:
        return images.image01;
      case 2:
        return images.image02;
      case 3:
        return images.image03;
      case 4:
        return images.image04;
      case 5:
        return images.image05;
      default:
        return images.image05;
    }
  }
  function getImageNoBg() {
    switch (selectedesSugs) {
      case 1:
        return imagesNoBg.image01;
      case 2:
        return imagesNoBg.image02;
      case 3:
        return imagesNoBg.image03;
      case 4:
        return imagesNoBg.image04;
      case 5:
        return imagesNoBg.image05;
      default:
        return imagesNoBg.image05;
    }
  }

  const [activeSidebar, setActiveSidebar] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const closeSidebar = () => {
    setActiveSidebar(null);
  };

  return (
    <div className="container flex flex-col gap-16 px-8">
      <div class="text-4xl font-light text-white w-full text-center md:mx-32 max-w-fit">
        Renta con nosotros {" "}
        <b className="font-medium">botellones y pide recambios</b> más facil que
        nunca
      </div>
      <div className="flex-col">
        <img
          src={getImageNoBg()}
          alt="Water Bottle"
          className="w-full block lg:hidden p-0 m-0 rounded-xl"
        />
        <div className="bg-white justify-between p-6 py-8 gap-8 rounded-3xl  flex flex-col sm:flex-row-reverse md:flex-row mb-16">
          <div className="relative w-full hidden lg:block px-5">
            <img
              src={getImage()}
              alt="Water Bottle"
              className="absolute z-0 inset-0 w-full h-full object-cover rounded-xl"
            />
          </div>
          <div className="w-full lg:w-3/4 flex flex-col justify-between">
            <h1 className="text-2xl font-medium text-gray-800 p-0 m-0 flex justify-between mb-1">
              {"Botellón y dispensador "}
              {"+ Recambios"}
            </h1>

            <div className="flex gap-2 flex-wrap mb-8">
              {/* <Badge variant="surface" color="indigo">
                9 Pasos de purificación
              </Badge> */}
              <Badge variant="surface" color="gray">
               20 lts
              </Badge>
              <Badge variant="surface" color="gray">
                Pide en línea o Whatsapp
              </Badge>
              <Badge variant="surface" color="gray">
                Recambio en 24h
              </Badge>
              <Badge variant="surface" color="gray">
                Domicilio Programado Gratis
              </Badge>
              <Badge variant="surface" color="gray">
                ♻️ Enfoque sustentable
              </Badge>
            </div>

            <div className="flex flex-col md:flex-row gap-3 Hola cortana items-start md:items-end justify-between">
              <NumbersSelect
                label="Botellones a rentar"
                maxNumber={20}
                minNumber={1}
                setCounter={setSelectedesSugs}
              />
              <DividerComponent
                direction="vertical"
                size={1}
                color="ebebeb"
                margin={10}
                hideOnMobile={true}
              />
              <NumbersSelect
                label="Recambios adicionales"
                maxNumber={30}
                minNumber={1}
                setCounter={setSelectedesRefills}
              />
              <DividerComponent
                direction="vertical"
                size={1}
                color="ebebeb"
                margin={10}
                hideOnMobile={true}
              />
              <div className="items-end justify-end">
                <Button
                  size="3"
                  className="bg-blue-600 text-white px-4 flex gap-1 py-2 rounded-md hover:bg-blue-700 transition-colors"
                  onClick={() => setActiveSidebar(true)}
                >
                  Continuar
                  {/* <img
                    src="/assets/icons/whatsapp-icon-white.svg"
                    alt="WhatsApp"
                  /> */}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sidepanel
        isOpen={!!activeSidebar}
        onClose={closeSidebar}
        type={"quickCart"}
      />
    </div>
  );
};

export default OrderStarter;
