import React, { createContext, useContext, useState } from "react";

export const SalesContext = createContext();

export const useSalesContext = () => useContext(SalesContext);

export const SalesProvider = ({ children }) => {
  const [selectedesSugs, setSelectedesSugs] = useState(1);
  const [selectedesRefills, setSelectedesRefills] = useState(1);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("+57 ");
  const [email, setEmail] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [addressDetails, setAddressDetails] = useState("");

  const contextValue = {
    selectedesRefills,
    selectedesSugs,
    setSelectedesRefills,
    setSelectedesSugs,
    name,
    setName,
    phone,
    setPhone,
    email,
    setEmail,
    selectedAddress,
    setSelectedAddress,
    addressDetails,
    setAddressDetails,
  };
  return (
    <SalesContext.Provider value={contextValue}>
      {children}
    </SalesContext.Provider>
  );
};
