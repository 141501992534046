import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import supabase from "../SupaCredentials";
export const SupaContext = createContext();

export const useSupaContext = () => useContext(SupaContext);

export const SupaProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState({});
  const [isActiveUser, setIsActiveUser] = useState(false);

  const login = useCallback(async (email, password) => {
    setLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) throw error;
      console.log(">>>>>>>>>>>", user.data);
      setUser(data.user);
      setIsActiveUser(true);
      return data.user;
    } catch (error) {
      console.error("Error logging in:", error.message);
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  }, []);

  const logout = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      setUser({});
      setIsActiveUser(false);
    } catch (error) {
      console.error("Error logging out:", error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        setUser(session?.user || null);
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const contextValue = {
    user,
    login,
    logout,
    loading,
    isActiveUser,
    error,
  };

  return (
    <SupaContext.Provider value={contextValue}>{children}</SupaContext.Provider>
  );
};
