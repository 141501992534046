// DeliverySection.jsx
import React from "react";
import { MapPinIcon } from "lucide-react";
import * as Form from "@radix-ui/react-form";
import { useSalesContext } from "../../../contexts/SalesContext";
import {
  isValidEmail,
  handlePhoneChange,
  handleEmailChange,
  handleNameChange,
} from "../../../scripts/generalScript";
import AddressInput from "../../ui/AddressInput";

const DeliverySection = () => {
  const { name, setName, phone, setPhone, email, setEmail } = useSalesContext();

  return (
    <div className="bg-white flex flex-col mx-6 md:mx-10 pb-40 rounded-xl">
      <p className="flex gap-2 text-xl font-medium items-center">
        <MapPinIcon />
        Entrega
      </p>

      <Form.Root
        className="w-full"
        onSubmit={(e) => e.preventDefault()}
        autoComplete="off"
      >
        <FormField
          name="field_name"
          label="Nombre y apellidos"
          type="text"
          value={name}
          onChange={(e) => handleNameChange(e, setName)}
          autoComplete="new-name"
        />
        <FormField
          name="field_phone"
          label="Número con WhatsApp"
          type="tel"
          value={phone}
          onChange={(e) => handlePhoneChange(e, setPhone)}
          disabled={(name || "").length < 4}
          autoComplete="new-phone"
        />
        <FormField
          name="field_email"
          label="Correo electronico"
          type="email"
          value={email}
          onChange={(e) => handleEmailChange(e, setEmail)}
          disabled={(phone || "").length < 16}
          autoComplete="new-mail"
        />
        <div
          className={`${
            isValidEmail(email) ? "" : "opacity-50 cursor-not-allowed"
          }`}
        >
          <AddressInput />
        </div>
      </Form.Root>
    </div>
  );
};

const FormField = ({
  name,
  label,
  type,
  value,
  onChange,
  disabled,
  autoComplete,
}) => (
  <Form.Field className="mb-4" name={name}>
    <div className="flex items-baseline justify-between">
      <Form.Label className="font-light text-sm text-gray-600">
        {label}
      </Form.Label>
    </div>
    <Form.Control asChild>
      <input
        className={`text-lg w-full p-2 border rounded-md bg-gray-50 mt-2 ${
          disabled ? "opacity-50 cursor-not-allowed" : ""
        }`}
        type={type}
        required
        placeholder=""
        value={value}
        onChange={onChange}
        disabled={disabled}
        autoComplete={autoComplete}
        name={autoComplete}
      />
    </Form.Control>
  </Form.Field>
);

export default DeliverySection;
