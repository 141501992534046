import React, { useState, useEffect } from "react";
import { Minus, Plus } from "lucide-react";

const Stepper = ({ value: propValue, onChange, min = 0, max = 100 }) => {
  const [value, setValue] = useState(propValue || min);

  useEffect(() => {
    setValue(Math.max(min, Math.min(max, propValue)));
  }, [propValue, min, max]);

  const handleIncrement = () => {
    const newValue = Math.min(value + 1, max);
    setValue(newValue);
    onChange(newValue);
  };

  const handleDecrement = () => {
    const newValue = Math.max(value - 1, min);
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <div className="text-lg max-h-[40px] min-h-[40px] bg-blue-100 rounded-md text-blue-500 font-medium flex justify-center items-center">
      <button
        onClick={handleDecrement}
        className="bg-transparent border-0 text-blue-500"
        disabled={value <= min}
      >
        <Minus size={20} />
      </button>
      <div className="max-w-[40px] min-w-[40px] flex justify-center items-center text-xl text-blue-600">
        {value}
      </div>
      <button
        onClick={handleIncrement}
        className="bg-transparent border-0 text-blue-500"
        disabled={value >= max}
      >
        <Plus size={20} />
      </button>
    </div>
  );
};

export default Stepper;
