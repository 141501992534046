import React from "react";

const DividerComponent = ({
  direction = "horizontal", // Puede ser 'horizontal' o 'vertical'
  size = 1, // Altura para horizontal, ancho para vertical
  color = "222222",
  margin = 0, // margen en ambos ejes
  hideOnDesktop = false,
  hideOnMobile = false, // Nueva prop para ocultar en móvil
}) => {
  const isHorizontal = direction === "horizontal";

  // Validar que el size sea un número positivo
  const validSize = Math.max(1, size);

  // Validar que el color sea un valor hexadecimal válido y eliminar el '#' si ya está
  const validColor = /^#?[0-9A-Fa-f]{6}$/i.test(color)
    ? color.replace("#", "")
    : "222222";

  // Validar que el margen sea un número (positivo o cero)
  const validMargin = Math.max(0, margin);

  return (
    <div
      className={`${hideOnDesktop ? "block lg:hidden" : ""} ${
        hideOnMobile ? "hidden lg:block" : ""
      }`} // Aplicar clases para ocultar en desktop o móvil
      style={{
        width: isHorizontal ? "100%" : `${validSize}px`, // Si es horizontal, ocupará todo el ancho
        height: isHorizontal ? `${validSize}px` : "100%", // Si es vertical, ocupará todo el alto
        backgroundColor: `#${validColor}`,
        margin: isHorizontal ? `${validMargin}px 0` : `0 ${validMargin}px`, // Margen en el eje Y para horizontal, eje X para vertical
        minHeight: isHorizontal ? undefined : "100px", // Asegura que tenga un tamaño mínimo si es vertical
      }}
    ></div>
  );
};

export default DividerComponent;
