import React from "react";
import QuickCart from "../store/QuickCart";
import Navbar from "../navigation/Navbar";

const RentSug = () => {
  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <Navbar type="new-order" staticPosition={true} />
      <div className="flex-1 overflow-hidden">
        <div className="h-full overflow-y-hidden lg:mx-40 bg-white">
          <QuickCart />
        </div>
      </div>
    </div>
  );
};

export default RentSug;
