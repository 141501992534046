import { Badge, Button } from "@radix-ui/themes";
import React, { useState, useEffect } from "react";
import DividerComponent from "../../ui/DividerComponent";
import Compare from "../../landingPage/Compare";
import * as Collapsible from "@radix-ui/react-collapsible";
import { ChevronDown, EllipsisVertical, MapPinHouse, Menu } from "lucide-react";
import { generateJugDummy } from "../../../scripts/generalScript";

const UserJugs = () => {
  const [jugs, setJugs] = useState(0);
  const [refills, setRefills] = useState(Math.floor(Math.random() * 8) + 1);
  const [open, setOpen] = React.useState(false);
  const [jugsList, setJugsList] = useState({});

  function changeState() {
    if (jugs === 0) {
      const calc = Math.floor(Math.random() * 8) + 1;
      setJugs(calc);
      return;
    }
    setJugs(0);
    return;
  }

  useEffect(() => {
    if (jugs > 0) {
      const jugsArray = [];
      for (let i = 0; i < jugs; i++) {
        jugsArray.push(generateJugDummy());
      }
      setJugsList(jugsArray);
      setRefills(Math.floor(Math.random() * 8));
    }
  }, [jugs]);

  return (
    <div className="py-2 md:px-0 flex flex-col gap-8">
      <Button onClick={changeState}>Switch</Button> 

      {jugs === 0 && (
        <section className="mb-8 flex flex-col justify-center items-center">
          <div className="grid lg:grid-cols-2 gap-4 md:gap-8 items-center">
            <div className="">
              <h1 className="text-5xl xl:text-6xl font-normal tracking-tight mb-12 text-medium p-0 m-0">
                Pide hoy <br />
                <span className="text-blue-600">tu botellón</span> y asegura tu
                suministro diario
              </h1>
              <a href="/ingresar">
                <Button size="3" className="text-lg">
                  Pedir botellón
                </Button>
              </a>
            </div>
            <div className="mb-12 lg:mb-0">
              <img
                src="https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/dashboard-publics/new-botellon-plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad.png"
                className="w-full"
                alt=""
              />
            </div>
          </div>

          <Collapsible.Root className="border-none bg-transparent cursor-pointer w-full">
            <Collapsible.Trigger className="flex flex-row items-center cursor-pointer w-full rounded-3xl px-4 force-font justify-between text-xl  bg-transparent">
              <p>Conoce todos los beneficios</p>
              <ChevronDown />
            </Collapsible.Trigger>
            <Collapsible.Content>
              <Compare container={false} />
            </Collapsible.Content>
          </Collapsible.Root>
        </section>
      )}
      {jugs > 0 && (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col lg:flex-row justify-between items-center p-6 py-4 gap-4 md:gap-8 text-center border-gray-900 border-b-2 bg-white rounded-2xl shadow-sm">
            <div className="flex items-center justify-start gap-4 w-full">
              <img
                className="h-8 lg:h-12 w-auto"
                src="https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/dashboard-publics/jug-total-status-img-2-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad.png"
                alt="recambios disponibles"
              />
              <p className="text-lg md:text-xl p-0  m-0 font-normal text-left w-full">
                Tienes{" "}
                <span className="font-medium">
                  {jugs}
                  {jugs > 0 ? " botellones rentados" : " botellón rentado"}
                </span>
              </p>
            </div>
            <DividerComponent
              color="eeeeee"
              size={2}
              direction="vertical"
              margin={0}
              hideOnMobile={true}
            />
            <DividerComponent
              color="eeeeee"
              size={0.5}
              direction="horizontal"
              margin={0}
              hideOnDesktop={true}
            />
            <div className="flex md:items-center justify-start gap-4 w-full">
              <img
                className="h-8 lg:h-12 w-auto"
                src="https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/dashboard-publics/jug-total-status-img-1-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad.png"
                alt="botellon disponible"
              />

              <div className="flex flex-col md:flex-row justify-between gap-2 md:gap-8 md:items-center w-full">
                <p className="text-lg md:text-xl p-0  m-0 font-normal text-left w-full">
                  {refills > 0 && "Tienes "}
                  <span className="font-medium w-full">
                    {refills > 0 && refills}
                    {refills === 0
                      ? "No tienes recambios disponibles"
                      : refills === 1
                      ? " recambio disponible"
                      : " recambios disponibles"}
                  </span>
                </p>
              </div>
            </div>
            <Button className="text-lg font-normal w-full lg:w-auto" size="3">
              Comprar recambios
            </Button>
          </div>
          <div class="  mx-full">
            {jugsList && jugsList.length > 0 ? (
              (() => {
                const groupedByAddress = jugsList.reduce((acc, item) => {
                  if (!acc[item.address_id]) {
                    acc[item.address_id] = [];
                  }
                  acc[item.address_id].push(item);
                  return acc;
                }, {});

                return Object.entries(groupedByAddress).map(
                  ([addressId, groupedItems]) => (
                    <div key={addressId} class="mb-8">
                      <DividerComponent
                        color="f0f0f0"
                        margin={24}
                        size={1}
                        direction="horizontal"
                      />
                      <h2 class="text-2xl font-medium flex gap-2 mb-4">
                        <MapPinHouse /> {addressId}
                      </h2>
                      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
                        {groupedItems.map((item) => (
                          <div
                            key={item.id}
                            class="bg-white shadow-lg rounded-3xl overflow-hidden flex flex-col"
                          >
                            <div className="flex justify-between p-4 gap-4 pb-0">
                              <div className="flex gap-2">
                                <div className="flex items-end">
                                  <img
                                    className="h-32"
                                    alt="botellón"
                                    src="https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/dashboard-publics/botellon-total-status-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad-min.jpg"
                                  />
                                </div>
                                <div className="flex flex-col gap-1 mb-4">
                                  <p class="text-gray-800 p-0 m-0 text-md font-medium">
                                    {item.id}
                                  </p>
                                  <p class="text-gray-400 p-0 m-0 text-sm">
                                    {"Último recambio " + item.purchase_date}
                                  </p>
                                  <Badge className="w-fit m-0" size="3">
                                    {item.status}
                                  </Badge>
                                </div>
                              </div>
                              {/* <h3 class="text-xl font-normal mb-2 p-0 m-0">
                                {"Botellón " + item.id}
                              </h3> */}
                              <EllipsisVertical className="min-h-6 min-w-6 max-h-6 max-h-6" />
                            </div>

                            <div
                              className={`h-12 flex justify-center items-center bg-blue-700 text-white cursor-pointer text-lg ${
                                refills === 0 && "disabled-forced"
                              }`}
                            >
                              Pedir Recambio
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                );
              })()
            ) : (
              <p class="text-xl text-gray-600">No items found.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserJugs;
