// CollapsibleSection.jsx
import React from "react";
import * as Collapsible from "@radix-ui/react-collapsible";
import { ArrowDownIcon } from "lucide-react";

const CollapsibleSection = ({ title, amount, period = "", value }) => (
  <Collapsible.Root>
    <Collapsible.Trigger asChild>
      <div className="flex justify-between items-end gap-0 my-4 mt-8 ">
        <p className="p-0 m-0 font-medium text-lg">{title}</p>
        <div className="flex flex-col justify-end items-end">
          <p className="flex justify-between text-xl font-medium p-0 m-0">
            ${amount}
            {period}
          </p>
          <p className="p-0 m-0 text-xs bg-transparent w-full flex items-center justify-end gap-1 text-gray-400">
            Detalles de este cobro <ArrowDownIcon size={12} />
          </p>
        </div>
      </div>
    </Collapsible.Trigger>
    <Collapsible.Content>{value}</Collapsible.Content>
  </Collapsible.Root>
);

export default CollapsibleSection;
