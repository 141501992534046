import React, { useState, useEffect } from "react";
import "./App.css";
import Index from "./componets/landingPage/Index";
import { SupaProvider } from "./contexts/SupaContext";
import { SalesProvider } from "./contexts/SalesContext";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useParams,
} from "react-router-dom";
import PrivateRoute from "./componets/utils/PrivateRoute";
import Login from "./componets/pages/Login";
import Admin from "./componets/admin/Admin";
import Dashboard from "./componets/dashboard/Dashboard";
import { Theme } from "@radix-ui/themes";
import PageNotFound from "./componets/navigation/PageNotFound";
import RentSug from "./componets/store/RentSug";

function App() {
  const [isWebView, setIsWebView] = useState(false);

  useEffect(() => {
    const checkWebView = () => {
      const isStandalone = window.matchMedia(
        "(display-mode: standalone)"
      ).matches;
      const isIOSStandalone = window.navigator.standalone;
      setIsWebView(isStandalone || isIOSStandalone);
    };
    checkWebView();
  }, []);

  return (
    <Theme panelBackground="solid" radius="large">
      <BrowserRouter>
        <SupaProvider>
          <SalesProvider>
            <Routes>
              <Route path="/" element={<Index />} />

              <Route path="/ingresar" element={<Login />} />
              <Route path="/nuevo-pedido" element={<RentSug />} />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/adminfl"
                element={
                  <PrivateRoute>
                    <Admin />
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard/*"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </SalesProvider>
        </SupaProvider>
      </BrowserRouter>
    </Theme>
  );
}

export default App;
