import React from "react";
import { Button } from "@radix-ui/themes";
import { formatPrice, isValidEmail } from "../../../scripts/generalScript";
import { useSalesContext } from "../../../contexts/SalesContext";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const TotalBar = ({ subtotal }) => {
  const {
    name,
    phone,
    email,
    selectedAddress,
    addressDetails,
    selectedesSugs,
    selectedesRefills,
  } = useSalesContext();
  const navigate = useNavigate();
  const isButtonEnabled =
    name.length > 3 &&
    phone.length === 16 &&
    isValidEmail(email) &&
    selectedAddress !== "" &&
    addressDetails !== "" &&
    (selectedesSugs > 0 || selectedesRefills > 0);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isButtonEnabled) {
      let missingFields = [];
      if (name.length <= 3) missingFields.push("Nombre y apellido");
      if (phone.length !== 16) missingFields.push("Número de WhatsApp");
      if (!isValidEmail(email)) missingFields.push("Correo electrónico");
      if (selectedAddress === "") missingFields.push("Dirección");
      if (addressDetails === "") missingFields.push("Detalles de la dirección");
      if (selectedesSugs === 0 && selectedesRefills === 0)
        missingFields.push("al menos un producto");

      toast.error(`Ups! Faltan algunos datos: ${missingFields.join(". ")}`);
    } else {
      toast.success("Todo listo, enviando tu pedido...", {
        style: {
          borderRadius: "10px",
          background: "#222",
          color: "#fff",
        },
      });

      // Retrasar la navegación para dar tiempo al toast de aparecer
      setTimeout(() => {
        navigate("/");
      }, 1500); // 1500 ms de retardo
    }
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 h-16 bg-white flex justify-between pt-2 pb-8 px-6 items-center shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)] z-[9999]">
      <div className="flex flex-col gap-0">
        <p className="text-sm font-normal p-0 m-0">Subtotal</p>
        <p className="text-2xl p-0 m-0 font-medium">${formatPrice(subtotal)}</p>
      </div>
      <Button
        className={`text-white px-4 ${
          isButtonEnabled ? "bg-blue-600" : "bg-gray-300"
        }`}
        size={"3"}
        onClick={handleSubmit}
      >
        Enviar pedido a asesor
      </Button>
      <Toaster />
    </div>
  );
};

export default TotalBar;
