// import React, { useState, useMemo } from "react";
// import DividerComponent from "../ui/DividerComponent";
// import { ArrowDownIcon, Droplets, MapPinIcon, RefreshCcw } from "lucide-react";
// import * as Form from "@radix-ui/react-form";
// import { Button } from "@radix-ui/themes";
// import Stepper from "../ui/Stepper";
// import { useSalesContext } from "../../contexts/SalesContext";
// import {
//   formatPrice,
//   isValidEmail,
//   handlePhoneChange,
//   handleEmailChange,
//   handleNameChange,
// } from "../../utils/generalScript";
// import * as Collapsible from "@radix-ui/react-collapsible";
// import AddressInput from "../ui/AddressInput";

// const QuickCart = () => {
//   const {
//     selectedesSugs,
//     setSelectedesSugs,
//     setSelectedesRefills,
//     selectedesRefills,
//     name,
//     setName,
//     phone,
//     setPhone,
//     email,
//     setEmail,
//     selectedAddress,
//     addressDetails,
//   } = useSalesContext();

//   const isButtonEnabled = useMemo(() => {
//     return (
//       name.length > 3 &&
//       phone.length === 16 &&
//       isValidEmail(email) &&
//       selectedAddress !== "" &&
//       addressDetails !== "" &&
//       (selectedesSugs > 0 || selectedesRefills > 0)
//     );
//   }, [
//     name,
//     phone,
//     email,
//     selectedAddress,
//     addressDetails,
//     selectedesSugs,
//     selectedesRefills,
//   ]);

//   function handleSubmit(event) {
//     event.preventDefault();
//     // Handle form submission
//   }

//   return (
//     <div className="overflow-y-auto max-h-[90vh]">
//       <div className="flex-grow overflow-y-auto pb-24 pt-8">
//         {/* Botellones section */}
//         <div className="bg-white flex flex-col mx-6 md:mx-10 rounded-xl gap-2">
//           <p className="flex gap-2 text-xl font-medium items-center">
//             <Droplets />
//             Botellones
//             <span className="text-sm px-1 rounded bg-blue-800 text-blue-50 font-normal">
//               En renta
//             </span>
//           </p>

//           <div className="flex justify-between items-center">
//             <div className="flex gap-2 items-center">
//               <img
//                 alt=""
//                 src="assets/img/landing/quickcart-03.png"
//                 className="max-h-[40px]"
//               />
//               <p className="m-0 text-gray-500">Botellones 20lt</p>
//             </div>
//             <Stepper
//               value={selectedesSugs}
//               onChange={setSelectedesSugs}
//               min={1}
//               max={20}
//             />
//           </div>
//           <div className="flex justify-between items-center gap-8">
//             <div className="flex gap-2 items-center text-gray-500">
//               <img
//                 alt=""
//                 src="assets/img/landing/quickcart-02.png"
//                 className="max-h-[40px]"
//               />
//               <p className="m-0 text-wrap text-gray-500">
//                 Dispensadores automaticos recargables
//               </p>
//             </div>
//             <p className="m-0 text-xl max-w-[56px] max-h-[40px] min-w-[56px] min-h-[40px] bg-blue-100 rounded-md text-blue-600 font-medium flex justify-center items-center">
//               {selectedesSugs}
//             </p>
//           </div>
//           <Collapsible.Root className="">
//             <Collapsible.Trigger asChild>
//               <div className="flex justify-between items-end gap-0 my-4 mt-8 ">
//                 <p className="p-0 m-0 font-medium text-lg">
//                   Total en botellones
//                 </p>
//                 <div className=" flex flex-col justify-end items-end">
//                   <p className="flex justify-between text-xl font-medium p-0 m-0">
//                     ${formatPrice(selectedesSugs * 9990)}/mes
//                   </p>
//                   <p className="p-0 m-0 text-xs bg-transparent w-full flex items-center justify-end gap-1 text-gray-400">
//                     Detalles de este cobro <ArrowDownIcon size={12} />
//                   </p>
//                 </div>
//               </div>
//             </Collapsible.Trigger>
//             <Collapsible.Content>sdsd</Collapsible.Content>
//           </Collapsible.Root>
//         </div>

//         <DividerComponent
//           color="f6f6f6"
//           margin={16}
//           size={16}
//           direction="horizontal"
//         />

//         {/* Recambios section */}
//         <div className="bg-white flex flex-col mx-6 md:mx-10 rounded-xl gap-2">
//           <p className="flex gap-2 text-xl font-medium items-center">
//             <RefreshCcw />
//             Recambios
//           </p>

//           <div className="flex justify-between items-center gap-8">
//             <div className="flex gap-2 items-center">
//               <img
//                 alt=""
//                 src="assets/img/landing/quickcart-01.png"
//                 className="max-h-[40px]"
//               />
//               <p className="m-0 text-gray-500">Recambios de botellón 20lt</p>
//             </div>
//             <Stepper
//               value={selectedesRefills}
//               onChange={setSelectedesRefills}
//               min={1}
//               max={30}
//             />
//           </div>
//           <Collapsible.Root className="">
//             <Collapsible.Trigger asChild>
//               <div className="flex justify-between items-end gap-0 my-4 mt-8">
//                 <p className="p-0 m-0 font-medium text-lg">
//                   Total en recambios
//                 </p>
//                 <div className=" flex flex-col justify-end items-end">
//                   <p className="flex justify-between text-xl font-medium p-0 m-0">
//                     ${formatPrice(selectedesRefills * 12990)}
//                   </p>
//                   <p className="p-0 m-0 text-xs bg-transparent w-full flex items-center justify-end gap-1 text-gray-400">
//                     Detalles de este cobro <ArrowDownIcon size={12} />
//                   </p>
//                 </div>
//               </div>
//             </Collapsible.Trigger>
//             <Collapsible.Content>sdsd</Collapsible.Content>
//           </Collapsible.Root>
//         </div>

//         <DividerComponent
//           color="f6f6f6"
//           margin={16}
//           size={16}
//           direction="horizontal"
//         />

//         {/* Entrega section */}
//         <div className="bg-white flex flex-col mx-6 md:mx-10 pb-40  rounded-xl">
//           <p className="flex gap-2 text-xl font-medium items-center">
//             <MapPinIcon />
//             Entrega
//           </p>

//           <Form.Root
//             className="w-full"
//             onSubmit={handleSubmit}
//             autoComplete="off"
//           >
//             <Form.Field className="mb-4" name="field_name">
//               <div className="flex items-baseline justify-between">
//                 <Form.Label className="font-light text-sm text-gray-600">
//                   Nombre y apellidos
//                 </Form.Label>
//               </div>
//               <Form.Control asChild>
//                 <input
//                   className="text-lg w-full p-2 border rounded-md bg-gray-50 mt-2"
//                   type="text"
//                   required
//                   placeholder=""
//                   value={name}
//                   onChange={(e) => handleNameChange(e, setName)}
//                   autoComplete="new-name"
//                   name="new-name"
//                 />
//               </Form.Control>
//             </Form.Field>
//             <Form.Field className="mb-4" name="field_phone">
//               <div className="flex items-baseline justify-between">
//                 <Form.Label className="font-light text-sm text-gray-600">
//                   Teléfono con WhatsApp
//                 </Form.Label>
//               </div>
//               <Form.Control asChild>
//                 <input
//                   className={`text-lg w-full p-2 border rounded-md bg-gray-50 mt-2 ${
//                     (name || "").length < 4
//                       ? "opacity-50 cursor-not-allowed"
//                       : ""
//                   }`}
//                   type="tel"
//                   required
//                   placeholder=""
//                   value={phone}
//                   onChange={(e) => handlePhoneChange(e, setPhone)}
//                   disabled={(name || "").length < 4}
//                   autoComplete="new-phone"
//                   name="new-phone"
//                 />
//               </Form.Control>
//             </Form.Field>
//             <Form.Field className="mb-4" name="field_email">
//               <div className="flex items-baseline justify-between">
//                 <Form.Label className="font-light text-sm text-gray-600">
//                   Correo electronico
//                 </Form.Label>
//               </div>
//               <Form.Control asChild>
//                 <input
//                   className={`text-lg w-full p-2 border rounded-md bg-gray-50 mt-2 ${
//                     (phone || "").length < 16
//                       ? "opacity-50 cursor-not-allowed"
//                       : ""
//                   }`}
//                   type="email"
//                   required
//                   placeholder=""
//                   value={email}
//                   onChange={(e) => handleEmailChange(e, setEmail)}
//                   disabled={(phone || "").length < 16}
//                   autoComplete="new-mail"
//                   name="new-mail"
//                 />
//               </Form.Control>
//             </Form.Field>
//             <div
//               className={`${
//                 isValidEmail(email) ? "" : "opacity-50 cursor-not-allowed"
//               }`}
//             >
//               <AddressInput />
//             </div>
//           </Form.Root>
//         </div>
//       </div>

//       {/* Fixed bottom bar */}
//       <div className="fixed bottom-0 left-0 right-0 h-16 bg-white flex justify-between pt-2 pb-8 px-6 items-center shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)] z-[9999]">
//         <div className="flex flex-col gap-0">
//           <p className="text-sm font-normal p-0 m-0">Subtotal</p>
//           <p className="text-2xl p-0 m-0 font-medium">
//             ${formatPrice(selectedesSugs * 9990 + selectedesRefills * 12990)}
//           </p>
//         </div>
//         <Button
//           className={`text-white px-4 ${
//             isButtonEnabled ? "bg-blue-600" : "bg-gray-300"
//           }`}
//           size={"3"}
//           disabled={!isButtonEnabled}
//           onClick={handleSubmit}
//         >
//           Enviar pedido a asesor
//         </Button>
//       </div>
//     </div>
//   );
// };

// export default QuickCart;

// QuickCart.jsx
import React from "react";
import { useSalesContext } from "../../contexts/SalesContext";
import DividerComponent from "../ui/DividerComponent";
import BottleSection from "./quick-cart-components/BottleSection";
import RefillSection from "./quick-cart-components/RefillSection";
import DeliverySection from "./quick-cart-components/DeliverySection";
import TotalBar from "./quick-cart-components/TotalBar";

const QuickCart = () => {
  const { selectedesSugs, selectedesRefills } = useSalesContext();

  return (
    <div className="overflow-y-auto max-h-[90vh]">
      <div className="flex-grow overflow-y-auto pb-24 pt-8">
        <BottleSection />
        <DividerComponent
          color="f6f6f6"
          margin={16}
          size={16}
          direction="horizontal"
        />
        <RefillSection />
        <DividerComponent
          color="f6f6f6"
          margin={16}
          size={16}
          direction="horizontal"
        />
        <DeliverySection />
      </div>
      <TotalBar subtotal={selectedesSugs * 9990 + selectedesRefills * 12990} />
    </div>
  );
};

export default QuickCart;
