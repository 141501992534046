// RefillSection.jsx
import React from "react";
import { RefreshCcw } from "lucide-react";
import Stepper from "../../ui/Stepper";
import { useSalesContext } from "../../../contexts/SalesContext";
import { formatPrice } from "../../../scripts/generalScript";
import CollapsibleSection from "./CollapsibleSection";

const RefillSection = () => {
  const { selectedesRefills, setSelectedesRefills } = useSalesContext();

  return (
    <div className="bg-white flex flex-col mx-6 md:mx-10 rounded-xl gap-2">
      <p className="flex gap-2 text-xl font-medium items-center">
        <RefreshCcw />
        Recambios
      </p>

      <div className="flex justify-between items-center gap-8">
        <div className="flex gap-2 items-center">
          <img
            alt=""
            src="assets/img/landing/quickcart-01.png"
            className="max-h-[40px]"
          />
          <p className="m-0 text-gray-500">Recambios de botellón 20lt</p>
        </div>
        <Stepper
          value={selectedesRefills}
          onChange={setSelectedesRefills}
          min={1}
          max={30}
        />
      </div>
      <CollapsibleSection
        title="Total en recambios"
        amount={formatPrice(selectedesRefills * 12990)}
      />
    </div>
  );
};

export default RefillSection;