import React, { useState, useEffect, useRef } from "react";
import { Loader, TrashIcon } from "lucide-react";
import * as Popover from "@radix-ui/react-popover";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import polygonData from "../../componets/pages/coverageComponents/perimeter.json";
import * as Collapsible from "@radix-ui/react-collapsible";
import { ArrowDownIcon } from "lucide-react";
import { cleanStringNumber } from "../../scripts/generalScript";
import { useSalesContext } from "../../contexts/SalesContext";

const GOOGLE_MAPS_API_KEY = "AIzaSyAC56gt3Q7GNrcq-SfEaBVrcjLfFGqPN0Y";
const WEBHOOK_URL =
  "https://hook.us1.make.com/vyi9sj1adobd7p6mc99do9ybxd2s1o9a";

const loadGoogleMapsApi = () => {
  const script = document.createElement("script");
  script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places,geometry`;
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);
  return new Promise((resolve) => {
    script.onload = resolve;
  });
};

export default function AddressInput() {
  const {
    selectedesSugs,
    selectedesRefills,
    name,
    phone,
    email,
    addressDetails,
    setAddressDetails,
    selectedAddress,
    setSelectedAddress,
  } = useSalesContext();

  const [input, setInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isValid, setIsValid] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const timeoutRef = useRef(null);
  const inputRef = useRef(null);
  const popoverRef = useRef(null);
  const autocompleteService = useRef(null);
  const placesService = useRef(null);
  const polygonRef = useRef(null);
  const [isManualChange, setIsManualChange] = useState(true);
  const addressDetailsRef = useRef(null);
  const addressDetailsInputRef = useRef(null);
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    loadGoogleMapsApi().then(() => {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
      placesService.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      const polygonCoords = polygonData.features[0].geometry.coordinates[0].map(
        (coord) => new window.google.maps.LatLng(coord[1], coord[0])
      );
      polygonRef.current = new window.google.maps.Polygon({
        paths: polygonCoords,
      });
    });
  }, []);

  useEffect(() => {
    if (!isManualChange) {
      setIsManualChange(true);
      return;
    }

    if (input.length > 2) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      setIsLoading(true);
      timeoutRef.current = setTimeout(() => {
        fetchSuggestions();
      }, 1800);
    } else {
      setSuggestions([]);
      setOpen(false);
      setIsLoading(false);
    }

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [input]);

  useEffect(() => {
    if (
      isValid &&
      addressDetailsRef.current &&
      addressDetailsInputRef.current
    ) {
      addressDetailsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      // Pequeño retraso para asegurar que el scroll ha terminado
      setTimeout(() => {
        addressDetailsInputRef.current.focus();
      }, 400);
    }
  }, [isValid]);

  const fetchSuggestions = () => {
    if (!autocompleteService.current) return;

    const request = {
      input: input,
      componentRestrictions: { country: "CO" },
      bounds: new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(10.9486, -74.8366),
        new window.google.maps.LatLng(11.0344, -74.7634)
      ),
      types: ["address"],
      strictBounds: true,
    };

    autocompleteService.current.getPlacePredictions(
      request,
      (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const barranquillaSuggestions = results.filter((result) =>
            result.description.toLowerCase().includes("barranquilla")
          );
          setSuggestions(barranquillaSuggestions);
          setNoResults(barranquillaSuggestions.length === 0);
          setOpen(barranquillaSuggestions.length > 0 || noResults);
        } else {
          setSuggestions([]);
          setNoResults(true);
          setOpen(true);
        }
        setIsLoading(false);
      }
    );
  };

  const handleSuggestionClick = (suggestion) => {
    setIsManualChange(false);
    setInput(suggestion.description);
    setSelectedAddress(suggestion.description);
    setSuggestions([]);
    setOpen(false);

    placesService.current.getDetails(
      { placeId: suggestion.place_id },
      (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const coordinates = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          validateLocation(coordinates);
          setSelectedCoordinates(coordinates);
        }
      }
    );

    inputRef.current.focus();
  };

  const validateLocation = (coordinates) => {
    if (!window.google || !window.google.maps || !polygonRef.current) {
      console.error("Google Maps or polygon not initialized");
      return;
    }

    const latLng = new window.google.maps.LatLng(
      coordinates.lat,
      coordinates.lng
    );
    const isInside = window.google.maps.geometry.poly.containsLocation(
      latLng,
      polygonRef.current
    );
    setIsValid(isInside);

    if (!isInside) {
      const cleanPhone = cleanStringNumber(phone);
      const leadData = {
        name,
        cleanPhone,
        email,
        address: input,
        coordinates,
        selectedesSugs,
        selectedesRefills,
        timestamp: new Date().toISOString(),
      };
      sendLeadToWebhook(leadData);
    }
  };

  const handleInputChange = (e) => {
    const newInput = e.target.value;
    setInput(newInput);

    if (newInput !== selectedAddress) {
      setSelectedAddress("");
      setSelectedCoordinates(null);
      setIsValid(null);
      setAddressDetails("");
    }
  };

  const clearAddress = () => {
    setInput("");
    setSelectedAddress("");
    setAddressDetails("");
    setSuggestions([]);
    setIsValid(null);
    setSelectedCoordinates(null);
    setOpen(false);
    setNoResults(false);
    setIsLoading(false);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const sendLeadToWebhook = async (leadData) => {
    try {
      const response = await fetch(WEBHOOK_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(leadData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      console.log("Lead enviado con éxito al webhook");
    } catch (error) {
      console.error("Error al enviar el lead al webhook:", error);
    }
  };

  return (
    <div className="w-full">
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Anchor>
          <div className="relative">
            <div className="flex items-baseline justify-between">
              <label
                className="font-light text-sm text-gray-600"
                htmlFor="address-field"
              >
                Dirección de entrega
              </label>
            </div>
            <div className="relative">
              <input
                ref={inputRef}
                id="address-field"
                type="text"
                value={input}
                onChange={handleInputChange}
                placeholder="Ej. Calle 50 # 46-10"
                className="text-lg w-full p-2 border rounded-md bg-gray-50 mt-2"
                autoComplete="new-address"
                name="new-address"
              />
            </div>
          </div>
          <div className="flex gap-2 items-center pt-2">
            {isLoading ? (
              <>
                Buscando
                <Loader className="animate-spin" size={16} />
              </>
            ) : noResults ? (
              <>
                <p className="p-0 m-0">Sin sugerencias.</p>
                <p
                  className="p-0 m-0 flex gap-1 items-center font-medium"
                  onClick={clearAddress}
                >
                  Borrar
                  <TrashIcon size={12} />
                </p>
              </>
            ) : (
              <></>
            )}
          </div>
        </Popover.Anchor>
        <Popover.Content
          ref={popoverRef}
          className="w-full max-h-60 overflow-auto bg-white rounded-md shadow-lg mt-1"
          style={{
            zIndex: 1001,
            width: inputRef.current
              ? `${inputRef.current.offsetWidth}px`
              : "100%",
          }}
        >
          <ScrollArea.Root type="auto">
            <ScrollArea.Viewport className="w-full h-full">
              {suggestions.length > 0 &&
                suggestions.map((suggestion) => (
                  <div
                    key={suggestion.place_id}
                    onClick={() => handleSuggestionClick(suggestion)}
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                  >
                    {suggestion.description}
                  </div>
                ))}
            </ScrollArea.Viewport>
            <ScrollArea.Scrollbar
              orientation="vertical"
              className="w-2.5 bg-gray-100 rounded-full"
            >
              <ScrollArea.Thumb className="bg-gray-300 rounded-full" />
            </ScrollArea.Scrollbar>
          </ScrollArea.Root>
        </Popover.Content>
      </Popover.Root>
      {isValid !== null && (
        <div
          className={`text-left p-2 rounded-md mt-4 ${
            isValid ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
          }`}
        >
          {isValid ? (
            "Podemos entregar en esta dirección"
          ) : (
            <>
              <p className="p-0 m-0 flex gap-1">
                Lo sentimos, estamos trabajando para llegar a tu dirección.
                <span
                  className="p-0 m-0 flex gap-1 items-center font-medium"
                  onClick={clearAddress}
                >
                  Borrar
                  <TrashIcon size={12} />
                </span>
              </p>
            </>
          )}
        </div>
      )}
      {selectedCoordinates && isValid && (
        <>
          <div className="w-full h-40 mt-4 rounded-md overflow-hidden flex items-center justify-center">
            <img
              src={`https://maps.googleapis.com/maps/api/staticmap?center=${selectedCoordinates.lat},${selectedCoordinates.lng}&zoom=17&size=640x+200&maptype=roadmap&markers=color:blue%7C${selectedCoordinates.lat},${selectedCoordinates.lng}&key=${GOOGLE_MAPS_API_KEY}`}
              alt="Mapa estático de la dirección"
              className="rounded-md"
            />
          </div>
          <div className="mt-4" ref={addressDetailsRef}>
            <label
              className="font-light text-sm text-gray-600"
              htmlFor="address-details"
            >
              Detalles de la dirección
            </label>
            <input
              id="address-details"
              ref={addressDetailsInputRef}
              type="text"
              value={addressDetails}
              onChange={(e) => setAddressDetails(e.target.value)}
              placeholder="Apartamento, oficina, referencia, etc."
              className="text-lg w-full p-2 border rounded-md bg-gray-50 mt-2"
              autocomplete="off"
            />
          </div>
          <Collapsible.Root className="">
            <Collapsible.Trigger asChild>
              <div className="flex justify-between items-end gap-0 my-4 mt-8">
                <p className="p-0 m-0 text-lg font-medium">Costos de entrega</p>
                <div className=" flex flex-col justify-end items-end">
                  <p className="flex justify-between text-xl font-medium p-0 m-0">
                    $ Gratis
                  </p>
                  <p className="p-0 m-0 text-xs bg-transparent w-full flex items-center justify-end gap-1 text-gray-400">
                    Detalles de este cobro <ArrowDownIcon size={12} />
                  </p>
                </div>
              </div>
            </Collapsible.Trigger>
            <Collapsible.Content>sdsd</Collapsible.Content>
          </Collapsible.Root>
        </>
      )}
    </div>
  );
}
