import React, { useState, useEffect } from "react";
import * as Popover from "@radix-ui/react-popover";
import { ChevronDown } from "lucide-react";

const NumbersSelect = ({ label, minNumber, maxNumber, setCounter }) => {
  const [selectedNumber, setSelectedNumber] = useState(minNumber);

  useEffect(() => {
    setSelectedNumber(minNumber);
  }, [minNumber]);

  const handleNumberClick = (number) => {
    setSelectedNumber(number);
    if (setCounter) {
      setCounter(number);
    }
  };

  const renderNumbers = () => {
    const numbers = [];
    for (let i = minNumber; i <= Math.min(maxNumber, minNumber + 3); i++) {
      numbers.push(
        <button
          key={i}
          onClick={() => handleNumberClick(i)}
          className={`w-10 h-10 rounded-full border-none flex items-center justify-center text-lg transition-colors ${
            selectedNumber === i
              ? "bg-blue-600 text-white font-bold"
              : "bg-gray-100 text-gray-700 hover:bg-gray-200"
          }`}
        >
          {i}
        </button>
      );
    }
    return numbers;
  };

  const renderPopoverContent = () => {
    return (
      <div className="grid grid-cols-4 gap-2 p-2 bg-white rounded-lg shadow-lg">
        {[...Array(maxNumber - minNumber + 1)].map((_, index) => (
          <button
            key={index + minNumber}
            onClick={() => handleNumberClick(index + minNumber)}
            className={`w-10 h-10 rounded-full border-none flex items-center justify-center text-lg transition-colors ${
              selectedNumber === index + minNumber
                ? "bg-blue-600 text-white font-bold"
                : "bg-gray-100 text-gray-700 hover:bg-gray-200"
            }`}
          >
            {index + minNumber}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col space-y-2">
      <label className="text-lg font-regular text-gray-800">{label}</label>
      <div className="flex items-center gap-3">
        {renderNumbers()}
        {maxNumber > minNumber + 3 && (
          <Popover.Root>
            <Popover.Trigger asChild>
              <button
                className={`w-auto min-w-[2.5rem] h-10 rounded-full border-none flex items-center justify-center px-3 text-lg transition-colors ${
                  selectedNumber > minNumber + 3
                    ? "bg-blue-600 text-white font-bold"
                    : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                }`}
              >
                {selectedNumber > minNumber + 3 ? (
                  <>
                    {selectedNumber}
                    <ChevronDown size={20} className="ml-1" />
                  </>
                ) : (
                  <ChevronDown size={20} />
                )}
              </button>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content className="z-50" sideOffset={5}>
                {renderPopoverContent()}
                <Popover.Arrow className="fill-white" />
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        )}
      </div>
    </div>
  );
};

export default NumbersSelect;
