// BottleSection.jsx
import React from "react";
import { Droplets } from "lucide-react";
import Stepper from "../../ui/Stepper";
import { formatPrice } from "../../../scripts/generalScript";
import CollapsibleSection from "./CollapsibleSection";
import { useSalesContext } from "../../../contexts/SalesContext";

const BottleSection = () => {
  const { selectedesSugs, setSelectedesSugs } = useSalesContext();

  return (
    <div className="bg-white flex flex-col mx-6 md:mx-10 rounded-xl gap-2">
      <p className="flex gap-2 text-xl font-medium items-center">
        <Droplets />
        Botellones
        <span className="text-sm px-1 rounded bg-blue-800 text-blue-50 font-normal">
          En renta
        </span>
      </p>

      <div className="flex justify-between items-center">
        <div className="flex gap-2 items-center">
          <img
            alt=""
            src="assets/img/landing/quickcart-03.png"
            className="max-h-[40px]"
          />
          <p className="m-0 text-gray-500">Botellones 20lt</p>
        </div>
        <Stepper
          value={selectedesSugs}
          onChange={setSelectedesSugs}
          min={1}
          max={20}
        />
      </div>
      <div className="flex justify-between items-center gap-8">
        <div className="flex gap-2 items-center text-gray-500">
          <img
            alt=""
            src="assets/img/landing/quickcart-02.png"
            className="max-h-[40px]"
          />
          <p className="m-0 text-wrap text-gray-500">
            Dispensadores automaticos recargables
          </p>
        </div>
        <p className="m-0 text-xl max-w-[56px] max-h-[40px] min-w-[56px] min-h-[40px] bg-blue-100 rounded-md text-blue-600 font-medium flex justify-center items-center">
          {selectedesSugs}
        </p>
      </div>
      <CollapsibleSection
        title="Total en botellones"
        amount={formatPrice(selectedesSugs * 9990)}
        period="/mes"
        value="aaaa"
      />
    </div>
  );
};

export default BottleSection;
